class Order {
    constructor(apiService = null) {
        this.obj_name = 'order';
        this.apiService = apiService; // 將 ApiService 實例儲存為屬性
    }

    // 共用的 API 呼叫方法
    async callApi(method, endpoint, parameter = {}, progressEvent = null) {
        const apiConfig = {
            url: `${this.apiService.url}${this.obj_name}/${endpoint}`,
            parameter,
        };

        return new Promise((resolve) => {
            if (method === 'get') {
                apiConfig.url += `?${this.apiService.getMethodEncryp(parameter)}`;
                this.apiService.getApi({
                    url: apiConfig.url,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'post') {
                this.apiService.postApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'put') {
                this.apiService.putApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'delete') {
                this.apiService.delApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                })
            }
        });
    }

    // API 方法
    /**
     * 取得等待邀請的帳號列表
     * 
     
     */

    async addOrder(parameter,progressEvent=null){
        return this.callApi('put', 'addOrder', parameter , progressEvent);
    }

    async getOrderInfo(parameter){
        return this.callApi('get', 'getOrderInfo', parameter);
    }

    async getOrderDenture(parameter){
        return this.callApi('get', 'getOrderDenture', parameter);
    }

    //訂單 - 訂單媒合技工所
    async addAssignMatch(parameter,progressEvent=null){
        return this.callApi('put', 'addAssignMatch', parameter , progressEvent);
    }

    async updClinicOrderSend(parameter,progressEvent=null){
        return this.callApi('put', 'updClinicOrderSend', parameter , progressEvent);
    }

    async getOrderHistory(parameter){
        return this.callApi('get', 'getOrderHistory', parameter);
    }

    async getClinicOrderSendInfo(parameter){
        return this.callApi('get', 'getClinicOrderSendInfo', parameter);
    }

    //訂單 - 取得診所待確認訂單
    async geClinicWaitlOrder(parameter){
        return this.callApi('get', 'geClinicWaitlOrder', parameter);
    }

    //訂單 - 取得診所未建立完成訂單
    async geClinicNotFinishOrder(parameter){
        return this.callApi('get', 'geClinicNotFinishOrder', parameter);
    }
    //訂單 - 取得技工所待診所確認訂單
    async geClinicWaitConfirmOrder(parameter){
        return this.callApi('get', 'geClinicWaitConfirmOrder', parameter);
    }
    async geDentlabWaitlOrder(parameter){
        return this.callApi('get', 'geDentlabWaitlOrder', parameter);
    }
    async geDentlabNotFinishOrder(parameter){
        return this.callApi('get', 'geDentlabNotFinishOrder', parameter);
    }
    async geDentlabWaitConfirmOrder(parameter){
        return this.callApi('get', 'geDentlabWaitConfirmOrder', parameter);
    }
    async updOrderAssign(parameter,progressEvent=null){
        return this.callApi('put', 'updOrderAssign', parameter , progressEvent);
    }

    async delDentlabAssign(parameter){
        return this.callApi('delete', 'delDentlabAssign', parameter);
    }

    async delDentlabMatch(parameter){
        return this.callApi('delete', 'delDentlabMatch', parameter);
    }

    async updOrderStatus(parameter,progressEvent=null){
        return this.callApi('put', 'updOrderStatus', parameter , progressEvent);
    }

    async updDentlabStyleFilePath(parameter,progressEvent=null){
        return this.callApi('put', 'updDentlabStyleFilePath', parameter , progressEvent);
    }

    async getDentlabStyleInfo(parameter){
        return this.callApi('get', 'getDentlabStyleInfo', parameter);
    }
    async updDentlabOrderSend(parameter,progressEvent=null){
        return this.callApi('put', 'updDentlabOrderSend', parameter , progressEvent);
    }

    async getDentlabOrderSendInfo(parameter){
        return this.callApi('get', 'getDentlabOrderSendInfo', parameter);
    }


}

export default Order;

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path:'/',
        name:'main',
        component: () => import(/* webpackChunkName: "about" */ '@/views/public/mainLayoutView.vue'),
        // redirect: { name: 'denture-order' },
        // children:[{
        //     path:'/denture-order',
        //     name:'denture-order',
        //     redirect: { name: 'denture-order-list' },
        //     children:dentureOrderRoutes
        // },{
        //     path:'/clinic',
        //     name:'clinic',
        //     redirect: { name: 'clinic-list' },
        //     children:clinicRoutes
        // },{
        //     path:'/account',
        //     name:'account',
        //     redirect: { name: 'account-list' },
        //     children:accountRoutes
        // },{
        //     path:'/company',
        //     name:'company',
        //     redirect: { name: 'company-info' },
        //     children:companyRoutes
        // }]
    },{
        path:'/enter/:token',
        name:'enter',
        component: () => import(/* webpackChunkName: "about" */ '@/views/enterView.vue'),
    }
]

const router = createRouter({
    base: process.env.VUE_APP_BASE_APP_URL,
    history: createWebHistory(process.env.VUE_APP_BASE_APP_URL),
    routes,
    mode:'history',
    scrollBehavior(to, from, savedPosition) {
        // 滾動到最上方
        return { top: 0 };
    }
})

let previousPath = null;

// ✅ 在這裡加入登入檢查邏輯
router.beforeEach((to, from, next) => {

    // 🚀 允許進入 `/enter/:token`，不做檢查
    if (to.path.startsWith('/enter/')) {
        return next();
    }
    // 取得登入狀態 localStorage dcp-dentLab-user'
    const isAuthenticated = localStorage.getItem('dcp-his-user') !== null;

    // ❌ 若未登入，導向登入頁面 (這裡假設登入頁為 `/login`)
    if (!isAuthenticated) {
        window.location.href = process.env.VUE_APP_MAIN_URL
        return
    }

    previousPath = from.fullPath; // 記錄前一個路由的 fullPath

    // ✅ 已登入，正常導航
    next();
});
export { router, previousPath }; // 匯出 previousPath，其他元件可讀取
export default router
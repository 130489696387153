import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import i18n from '@/i18n'
import { createStore } from 'vuex'
import stores from './store/index'
// import { initializeApp } from "firebase/app";
// import '@/assets/css/modern.css'

// import '@/assets/js/app'
import '@/assets/css/dcp.css'

import ApiService from '@/assets/js/apiService'; // 引入 ApiService 實例
import shareds from '@/assets/js/shared.js'
import Parameters from '@/assets/js/parameters'

const store = createStore(stores)
const app = createApp(App);

const apiService = new ApiService(app);
app.config.globalProperties.$api = apiService;
const parameter = new Parameters()
app.config.globalProperties.$parameter = parameter;
const shared = new shareds()
app.config.globalProperties.$shared = shared;

const firebaseConfig = {
    apiKey: "AIzaSyCRfGnfcDs7tBvesdDvsRJ4L8SXyBFq5z8",
    authDomain: "dcp-test-186fb.firebaseapp.com",
    projectId: "dcp-test-186fb",
    storageBucket: "dcp-test-186fb.appspot.com",
    messagingSenderId: "158257781334",
    appId: "1:158257781334:web:5c8499ba30688ee17e39f7",
    measurementId: "G-2KVHDBJJMT"
};
// initializeApp(firebaseConfig);
// const firbaseObj = initializeApp(firebaseConfig);

app.use(router);
app.use(store);
app.use(i18n)
app.mount('#app');
  

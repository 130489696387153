<template>
  <div id="app">
    <router-view/>
    <fullScreenLoadingView></fullScreenLoadingView>
  </div>
</template>

<script>
import fullScreenLoadingView from '@/views/fullScreenLoadingView.vue'
export default {
    name: 'App',
    components: {
      fullScreenLoadingView
    },
    data(){
      return {}
    },
    watch:{
      
    },
    methods:{
      
    },
    mounted(){
    },
    created(){
      
    }
}
</script>
<style>

</style>
